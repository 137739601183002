import { render, staticRenderFns } from "./send-ticket.vue?vue&type=template&id=203dec86&scoped=true"
import script from "./send-ticket.js?vue&type=script&lang=js&external"
export * from "./send-ticket.js?vue&type=script&lang=js&external"
import style0 from "vue-multiselect/dist/vue-multiselect.min.css?vue&type=style&index=0&prod&lang=css&external"
import style1 from "./send-ticket.vue?vue&type=style&index=1&id=203dec86&prod&scoped=true&lang=css"


/* normalize component */
import normalizer from "!../../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "203dec86",
  null
  
)

export default component.exports