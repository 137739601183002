<template>
    <div id="attached-model">
        <transition name="modal">
            <div class="modal-mask">
                <div class="modal modal-wrapper">
                    <div class="modal-dialog" role="document">
                        <div class="modal-content" style="background-color: white;">
                            <div class="modal-header">
                                <h5 class="modal-title" id="exampleModalLabel2">Attach Billing</h5>
                                <button type="button" class="close" aria-label="Close" @click="$emit('close')">
                                    <span aria-hidden="true">&times;</span>
                                </button>
                            </div>
                            <div class="modal-body">
                                <select class="form-select form-select-lg mb-3" aria-label=".form-select-lg example"
                                    v-model="selectAttacheRfNumber">
                                    <!-- <option selected>Select</option> -->
                                    <option v-for="(rf, index) in rfList" :key="'rf' + index">{{ rf }}</option>
                                </select>
                            </div>
                            <div class="modal-footer">
                                <button type="button" class="btn btn-primary" @click="AttachedRfNumber">Attach</button>
                            </div>
                        </div>
                    </div>

                </div>
            </div>
        </transition>
    </div>
</template>

<script>
import vue from 'vue'
import store from "@/store/index.js";
export default {
    name: 'attached-model',
    data: () => ({
        selectAttacheRfNumber: '',
        rfList: [],
    }),
    props: {
        sales: Array,
        getSales: {
            type: Function,
            required: true,
        },
    },
    methods: {
        getRfsForAttacheBillingInfo() {
            let _vm = this;
            const rf = _vm.sales[0].sale_reference.id;
            _vm.axios
                .get("get-rfs-for-attach-billing-info/" + rf)
                .then(function (response) {
                    _vm.rfList = response.data.data;

                })
                .catch(function (error) {

                });
        },
        AttachedRfNumber() {
            let _vm = this;
            store.state.isLoaderShow = true;
            const rf = _vm.sales[0].sale_reference.id;
            const SARN = _vm.selectAttacheRfNumber;
            _vm.axios
                .get(`/attach-billing-info/` + SARN + `/` + rf)
                .then(function (response) {
                    _vm.$emit('close');
                    store.state.isLoaderShow = false;
                    window.location.reload();

                })
                .catch(function (error) {

                });
        },

    },
    computed: {


    },
    mounted() {
        this.getRfsForAttacheBillingInfo();
    }
}
</script>
<style scoped>
.modal-mask {
    position: fixed;
    z-index: 9998;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: rgba(0, 0, 0, .5);
    display: table;
    transition: opacity .3s ease;
}

.modal-wrapper {
    display: table-cell;
    vertical-align: middle;
}

.v-toast .v-toast--bottom {
    z-index: 9999 !important;
}

.modal-body {
    max-height: calc(100vh - 180px);
    overflow-y: auto;
}
</style>