<template>
  <div class="mb-2" id="notes">
    <div class="col-md-12 pb-2">
      <div class="row p-1">
        <h5 class="mt-1 title col-md-6">Notes</h5>
        <div class="text-right col-md-6">
          <button type="button" class="btn mr-2 border-none active btn-sm" @click="showNote = true">Add Note
          </button>
          <label class="mr-2" style="color:black">Expand/Collapse</label>
        </div>
      </div>
      <div class="mb-2 table-responsive">
        <table class="col-md-12 table table-striped table-earning table-borderless  p-0 ">
          <thead>
            <tr style="background-color: #553b9b61">
              <th>Agent</th>
              <th>Time</th>
              <th>Note</th>
            </tr>
          </thead>
          <tbody v-for="(note, k) in saleNotes" :key="k">
            <tr>
              <!-- <td>{{ note.agent.firstname }} {{ note.agent.lastname }}</td> -->
              <td>{{ note.agent_name }}</td>
              <td>{{ note.created_at | formatDateShort }}</td>
              <td>{{ note.notes }}</td>
            </tr>
          </tbody>
        </table>
      </div>
    </div>
    <div v-if="showNote" id="contact-us-comment">
      <transition name="modal">
        <div class="modal-mask">
          <div class="modal modal-wrapper">
            <div class="modal-dialog modal-md">
              <div class="modal-content">
                <div class="modal-header">
                  <h4 class="modal-title">Add Note</h4>
                  <button type="button" class="close" @click="showNote = false">
                    <span aria-hidden="true">&times;</span>
                  </button>
                </div>
                <div class="modal-body">
                  <div class="form-group">
                    <textarea rows="4" v-model="note.notes" placeholder="Add Note..."
                      class="au-input au-input--full form-control"></textarea>
                  </div>
                </div>
                <div class="modal-footer">
                  <button type="button" class="btn btn-primary active" @click="addNote()">Save</button>
                </div>
              </div>
            </div>
          </div>
        </div>
      </transition>
    </div>
  </div>
</template>
<style scoped>
.modal-mask {
  position: fixed;
  z-index: 9998;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, .5);
  display: table;
  transition: opacity .3s ease;
}

.modal-wrapper {
  display: table-cell;
  vertical-align: middle;
  overflow: hidden;
}
</style>
<script>
export default {
  data() {
    return {
      saleNotes: [],
      error: "",
      showLoader: false,
      showNote: false,
      note: {
        sale_id: '',
        notes: ''
      },
    }
  },
  props: {
    sale_id: {}
  },
  methods: {
    //     formatDateTime(timestamp) {
    //   const date = new Date(timestamp);
    //   const months = [
    //     "Jan", "Feb", "Mar", "Apr", "May", "Jun",
    //     "Jul", "Aug", "Sep", "Oct", "Nov", "Dec"
    //   ];

    //   const month = months[date.getMonth()];
    //   const day = date.getDate().toString().padStart(2, '0');
    //   const year = date.getFullYear().toString().slice(2);
    //   const time = timestamp.slice(11, 16);

    //   return `${month}/${day}/${year} ${time}`;
    // },
    getNotes() {
      let _vm = this;
      _vm.showLoader = true;
      this.axios
        .get("/sale-notes-by-sale/" + this.sale_id)
        .then(function (response) {
          _vm.showLoader = false;
          _vm.saleNotes = response.data.data;
        })
        .catch(function () { });
    },
    addNote() {
      let _vm = this;
      this.note['sale_id'] = this.sale_id;
      this.axios
        .post("/sale-notes", this.note)
        .then(function () {
          _vm.showNote = false;
          _vm.note.notes = '';
          _vm.getNotes();
        })
        .catch(function () { });
    },
  },
  mounted() {
    this.getNotes();
  }
}
</script>
