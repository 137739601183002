<template>
    <div class="row" id="passenger-info">
        <div class="p-0">
            <h5 class="p-2">Passenger Info</h5>
        </div>
        <div class="">
            <table class="col-md-12 table table-striped table-earning table-borderless  p-0">
                <thead>
                    <tr class="text-center float-center" style="background-color: #553b9b61">
                        <th>Gender</th>
                        <th>PTC</th>
                        <th>Last Name</th>
                        <th>First Name</th>
                        <th>Middle Name</th>
                        <th>Date of Birth</th>
                        <th>Phone</th>
                        <th>Email</th>
                    </tr>
                </thead>
                <tbody>

                    <tr class="text-center" v-for="(passengers, index) in salesPassengers" :key="index">
                        <td>
                            <select class="au-input form-control-sm" v-model="passengers.gender">
                                <option value="Male">Male</option>
                                <option value="Female">Female</option>
                            </select>
                        </td>
                        <td>
                            <select class="au-input form-control-sm" v-model="passengers.email">
                                <option value="Adult">Adult</option>
                                <option value="Child">Child</option>
                                <option value="Infant">Infant</option>
                            </select>
                        </td>
                        <td>
                            <input type="text" v-model="passengers.last_name" class="form-control au-input">
                        </td>
                        <td>
                            <input type="text" v-model="passengers.first_name" class="form-control au-input">
                        </td>
                        <td>
                            <input type="text" v-model="passengers.middle_name" class="form-control au-input">
                        </td>
                        <td>
                            <input v-model="passengers.date_of_birth" type="date" class="form-control au-input">
                        </td>
                        <td>
                            <input type="text" v-model="passengers.contact_phone_number" class="form-control au-input">
                        </td>
                        <td>
                            <input type="text" v-model="passengers.contact_email" class="form-control au-input">
                        </td>
                    </tr>
                </tbody>
            </table>
        </div>
    </div>
</template>
<script>
export default {
    data() {
        return {
            passengerInfo: {
                id: 0
            }
        }
    },
    props: {
        salesPassengers: {
            type: Array
        }
    },

}
</script>
