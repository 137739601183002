<template>
   <div class="row" id="gp-bonus">
        <div class="col-md-12">
            <div style="background-color:gray; color:white">
                <h5 class="p-2">GP Bonus</h5>
            </div>
            <div class="table-responsive" style="background-color:white; color:black;">
                <table class="col-md-12 table table-light table-borderless"
                        style="background-color:white; color:black;">	
                    <thead class="thead-light">
                        <tr class="text-center float-center">
                            <th>Amount</th>
                            <th>Status</th>
                            <th>Processed By</th>
                            <th>Checked By</th>
                            <th>Approved By</th>
                            <th></th>
                        </tr>       
                    </thead>
                    <tbody>
                        <tr class="text-center"
                            v-for="(gb, index) in gpBonus"
							:key="index">
                            <td>
                                <input v-model="gb.amount"
                                    type="text"
                                    class="au-input"
                                />
                            </td>
                            <td>
                                <select class="au-input form-control-sm"
                                    v-model="gb.status">
                                    <option value="Charged">Charged</option>
                                    <option value="Cancelled">Cancelled</option>	
                                </select>
                            </td>
                            <td>
                                <input v-model="gb.processed_by"
                                    type="text"
                                    class="au-input"
                                />
                            </td>
                            <td>
                                <input v-model="gb.checked_by"
                                    type="text"
                                    class="au-input"
                                />
                            </td>
                            <td>
                                <input v-model="gb.approved_by"
                                    type="text"
                                    class="au-input"
                                />
                            </td>
                        </tr>
                    </tbody>
                </table>
            </div>
        </div>
    </div> 
</template>
<script>
export default {
     data() {
        return {
            gpBonus:{
                id:0
            }
        }
    }
}
</script>