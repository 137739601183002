<template>
<div class="row" id="financial-info">
    <div class="p-0">
        <h5 class="p-2">Financial Info</h5>
    </div>
    <div class="table-responsive">
        <table class="col-md-12 table table-striped table-earning table-borderless p-0">
            <thead>
                <tr class="text-center float-center" style="background-color: #553b9b61">
                    <th>PAX Type</th>
                    <th>Name</th>
                    <th>Ticket#</th>
                    <th>Fare Type</th>
                    <th>Consolidator</th>
                    <th>Selling Price</th>
                    <th>Net Price</th>
                    <th>Commission/Dropnet</th>
                    <th>Service Fee</th>
                    <th>Issuing Fee</th>
                    <th></th>
                </tr>
            </thead>
            <tbody v-for="(fp,index) in financialInfo.financial_pax" :key="index">
                <tr class="text-center float-center col-md-12 small">
                    <td>
                        <select v-model="fp.pax_type" class="au-input form-control">
                            <option value="Adult">Adult</option>
                            <option value="Child">Child</option>
                            <option value="Infant">Infant</option>
                        </select>
                    </td>
                    <td>
                        <input v-model="fp.name" type="text" class="form-control au-input">
                    </td>
                    <td>
                        <validation-provider name="ticket" rules="decimal" v-slot="{ errors }">
                            <input type="text" v-model="fp.ticket" name="ticket" class="au-input form-control">
                            <span class="invalid-feedback">{{
                                        errors[0]
                                    }}</span>
                        </validation-provider>
                    </td>
                    <td>
                        <input v-model="fp.fare_type" type="text" class="form-control au-input">
                    </td>
                    <td>
                        <input v-model="fp.consolidator" type="text" class="form-control au-input">
                    </td>
                    <td>
                        <validation-provider name="selling_price" rules="decimal" v-slot="{ errors }">
                            <input v-model="fp.selling_price" type="text" class="au-input form-control">
                            <span class="invalid-feedback">{{
                                        errors[0]
                                    }}</span>
                        </validation-provider>
                    </td>
                    <td>
                        <validation-provider name="net_price" rules="decimal" v-slot="{ errors }">
                            <input v-model="fp.net_price" type="text" class="au-input form-control">
                            <span class="invalid-feedback">{{
                                        errors[0]
                                    }}</span>
                        </validation-provider>
                    </td>
                    <td>
                        <validation-provider name="commission" rules="decimal" v-slot="{ errors }">
                            <input v-model="fp.commission" type="text" class="au-input form-control">
                            <span class="invalid-feedback">{{
                                        errors[0]
                                    }}</span>
                        </validation-provider>
                    </td>
                    <td>
                        <validation-provider name="service_fee" rules="decimal" v-slot="{ errors }">
                            <input v-model="fp.service_fee" type="text" class="au-input form-control">
                            <span class="invalid-feedback">{{
                                        errors[0]
                                    }}</span>
                        </validation-provider>
                    </td>
                    <td>
                        <validation-provider name="issuing_fee" rules="decimal" v-slot="{ errors }">
                            <input v-model="fp.issuing_fee" type="text" class="au-input form-control">
                            <span class="invalid-feedback">{{
                                        errors[0]
                                    }}</span>
                        </validation-provider>
                    </td>
                    <td>
                        <input v-if="financialInfo.financial_pax.length > 0 && index>0 && financialInfo.financial_pax.length!=1"
                        type="button" class="btn  mr-2 border-none active btn-sm btn-danger" @click="removePax(fp.id, index)"
                        value="Remove">
                        <br>
                        <input v-if="financialInfo.financial_pax.length==index+1" type="button"
                        class="btn mr-2 border-none active btn-sm btn-primary" @click="addPax" value="Add PAX +">
                    </td>
                </tr>
            </tbody>
        </table>
        <div class="text-right">
            <input v-if="financialInfo.financial_pax.length===0" type="button" class="btn mr-2 border-none active btn-sm btn-primary" @click="addPax" value="Add PAX +">
        </div>
        <div class="p-2 table-responsive" style="background-color:white; color:black;">
            <table :data="financialInfo">
                <tbody>
                    <tr class="p-2 mb-2">
                        <th>
                        <td>Payment Method</td>
                        <td class="p-2 mr-3">
                            <select class="ml-2 p-2 mr-2 form-control" v-model="financialInfo.payment_method">
                                <option value="Disclose MCO">Disclose MCO</option>
                                <option value="Single Charge">Single Charge</option>
                                <option value="Wire Transfer">Wire Transfer</option>
                            </select>
                        </td>
                        <td class="p-2"> Will Use 2 CC?</td>
                        <td>
                            <input type="checkbox" v-model="financialInfo.will_use_two_cc" class="ml-2 checkbox">
                        </td>
                        </th>
                    </tr>
                    <tr class="col-md-6" v-if="financialInfo.payment_method === 'Disclose MCO'">
                        <table class="p-2 mt-2 table col-md-6">
                            <thead class="bg-light text-center table-bordered">
                                <tr>
                                    <th>Issuing Airlines</th>
                                    <th>MCO</th>
                                    <th>Additional MCO</th>
                                </tr>
                            </thead>
                            <tbody class="table-bordered">
                                <tr>
                                    <td>
                                        <validation-provider name="issuing_airlines" v-slot="{ errors }">
                                            <input v-model="financialInfo.issuing_airlines" type="currency" class="au-input form-control">
                                            <span class="invalid-feedback">{{
                                                            errors[0]
                                                        }}</span>
                                        </validation-provider>
                                    </td>
                                    <td>
                                        <validation-provider name="mco" v-slot="{ errors }">
                                            <input v-model="financialInfo.mco" type="currency" class="au-input form-control">
                                            <span class="invalid-feedback">{{
                                                            errors[0]
                                                        }}</span>
                                        </validation-provider>
                                    </td>
                                    <td>
                                        <validation-provider name="additional_mco" v-slot="{ errors }">
                                            <input v-model="financialInfo.additional_mco" type="currency" class="au-input form-control">
                                            <span class="invalid-feedback">{{
                                                            errors[0]
                                                        }}</span>
                                        </validation-provider>
                                    </td>
                                </tr>
                            </tbody>
                        </table>
                    </tr>
                    <tr>
                        <td class="float-left">
                        <th>
                            <br />
                    <tr>
                        <td class="p-2">Credit Card Fee</td>
                        <td>
                            <validation-provider name="credit_card_fee" v-slot="{ errors }">
                                <input v-model="financialInfo.credit_card_fee" type="currency" class=" au-input form-control">
                                <span class="invalid-feedback">{{
                                                        errors[0]
                                                    }}</span>
                            </validation-provider>
                        </td>
                        <td class="p-2">Gross Profit</td>
                        <td>
                            <validation-provider name="gross_profit" v-slot="{ errors }">
                                <input v-model="financialInfo.gross_profit" type="currency" class=" au-input form-control">
                                <span class="invalid-feedback">{{
                                                        errors[0]
                                                    }}</span>
                            </validation-provider>
                        </td>
                    </tr>
                    <tr>
                        <td class="p-2">Processing Fee</td>
                        <td>
                            <validation-provider name="processing_fee" v-slot="{ errors }">
                                <input v-model="financialInfo.processing_fee" type="currency" class=" au-input form-control">
                                <span class="invalid-feedback">{{
                                                            errors[0]
                                                        }}</span>
                            </validation-provider>
                        </td>
                        <td class="p-2">Agent's profit</td>
                        <td>
                            <validation-provider name="agent's_profit" v-slot="{ errors }">
                                <input v-model="financialInfo.agents_profit" type="currency" class=" au-input form-control">
                                <span class="invalid-feedback">{{
                                                            errors[0]
                                                        }}</span>
                            </validation-provider>
                        </td>
                    </tr>
                    </th>
                    </td>
                    </tr>
                </tbody>
            </table>
        </div>
    </div>
</div>
</template>

<script>
export default {
    data() {
        return {
            financialInfo: this.value
        }
    },
    props: {
        type: String,
        value: {
            type: Object
        }
    },
    watch: {
        value: {
            handler: function (newValue) {
                this.financialInfo = newValue;
            },
            deep: true
        },
        financialInfo: {
            handler: function (newValue) {
                this.$emit("input", newValue);
            },
            deep: true
        }
    },
    methods: {
        calculateAgentProfit() {
            //   Agent's profit =	Selling - Net + Dropnet - service fee - issuing - Credit Card - processing
            // let selling =0;
            // this.financialInfo.financial_pax.forEach(element => {
            //     selling+=element.selling_price;
            // });
        },
        addPax() {
            this.financialInfo.financial_pax.push({
                pax_type: '',
                name: '',
                ticket: '',
                fare_type: '',
                consolidator: '',
                selling_price: '',
                net_price: '',
                commission: '',
                service_fee: '',
                issuing_fee: ''
            })
        },
        removePax(id, index) {
            if (id) {
                let _vm = this;
                this.$dialog
                    .confirm("Are you sure you want to delete this record?")
                    .then(function () {
                        _vm.axios
                            .delete("/financial-pax/" + id)
                            .then(function () {
                                _vm.$emit('update-sales');
                            })
                            .catch(function (error) {
                            });
                    })
                    .catch(function (error) {
                    });
            } else {
                this.financialInfo.financial_pax.splice(index, 1)
            }
        },
    }
}
</script>
