<template>
    <div class="row" id="hotel">
        <div class="col-md-12">
            <div style="background-color:gray; color:white">
                <div class="row p-1">
                    <h5 class="title col-md-6">Hotel</h5>
                    <div class="text-right col-md-6 ">
                        <label style="color:black">Status</label>
                        <select class="ml-2" v-model="hotel.status"
                            style="background:transparent; appearance: none; border: none; outline: none;">
                            <option value="Open">Open</option>
                            <option value="Booked">Booked</option>
                            <option value="Paid">Paid</option>
                            <option value="Cancelled">Cancelled</option>
                        </select>
                    </div>
                </div>
            </div>
            <div class="table-responsive">
                <table class="table table-borderless" style="background-color:white; color:black;">
                    <thead class="thead-light text-center">
                        <tr>
                            <th>Hotel Information</th>
                            <th>Guests Information</th>
                            <th>Financial Information</th>
                            <th>Will Use</th>
                        </tr>
                    </thead>
                    <tbody class="text-center">
                        <tr>
                            <td class="col-md-3">
                        <tr>
                            <td>Title</td>
                            <td>
                                <input v-model="hotel.title" type="text" class="au-input" />
                            </td>
                        </tr>
                        <tr>
                            <td>City</td>
                            <td>
                                <input v-model="hotel.city" type="text" class="au-input" />
                            </td>
                        </tr>
                        <tr>
                            <td>Address</td>
                            <td>
                                <input v-model="hotel.address" type="text" class="au-input form-control" />
                            </td>
                        </tr>
                        <tr>
                            <td>Room Type</td>
                            <td>
                                <input v-model="hotel.room_type" type="text" class="au-input form-control" />
                            </td>
                        </tr>
                        <tr>
                            <td>Vender</td>
                            <td>
                                <select class="au-input form-control" v-model="hotel.vendor">
                                    <option value="Expedia">Expedia</option>
                                    <option value="Booking.com">Booking.com</option>
                                    <option value="Other">Other</option>
                                </select>
                            </td>
                        </tr>
                        </td>
                        <td class="col-md-6">
                            <tr>
                                <th class="col-md-6">
                                <td>
                                    <label>Check In
                                        <input v-model="hotel.check_in" class="au-input" type="date">
                                    </label>
                                </td>
                                <td>
                                    <label>Check Out
                                        <input v-model="hotel.check_out" class="au-input" type="date">
                                    </label>
                                </td>
                                </th>
                            </tr>
                            <tr>
                                <th class="col-md-6">
                                    <table class="table-responsive">
                                        <thead class="text-center">
                                            <tr>
                                                <th>First Name</th>
                                                <th>Last Name </th>
                                                <th>Guest Type</th>
                                            </tr>
                                        </thead>
                                        <tbody>
                                            <tr v-for="(hg, index) in hotel.hotel_guests" :key="index">
                                                <td>
                                                    <input v-model="hg.first_name" type="text"
                                                        class="au-input form-control" />
                                                </td>
                                                <td>
                                                    <input v-model="hg.last_name" type="text"
                                                        class="au-input form-control" />
                                                </td>
                                                <td class="text-center">
                                                    <select v-model="hg.guest_type" class="au-input form-control-sm">
                                                        <option value="Adult">Adult</option>
                                                        <option value="Child">Child</option>
                                                    </select>
                                                </td>
                                                <td>
                                                    <div class="float-left">
                                                        <input v-if="hotel.hotel_guests.length == index + 1" type="button"
                                                            class="btn border-none active btn-sm btn-primary"
                                                            @click="addGuest" value="Add Guest +">
                                                        <input
                                                            v-if="hotel.hotel_guests.length > 0 && index > 0 && hotel.hotel_guests.length != 1"
                                                            type="button"
                                                            class="btn ml-1 border-none active btn-sm btn-danger"
                                                            @click="removeGuest(hg.id, index)" value="Remove">
                                                    </div>
                                                </td>
                                            </tr>
                                        </tbody>
                                    </table>
                                </th>
                            </tr>
                        </td>
                        <td class="col-md-2">
                            <tr>
                                <td>Currency </td>
                                <td class="col-md-2">
                                    <select id="currency" class="au-input form-control-sm" v-model="hotel.currency">
                                        <option value="USD">USD</option>
                                        <option value="CAD">CAD</option>
                                        <option value="GBP">GBP</option>
                                        <option value="EUR">EUR</option>
                                    </select>
                                </td>
                            </tr>
                            <tr>
                                <td>Selling Price</td>
                                <td>
                                    <input v-model="hotel.selling_price" type="text" class="au-input form-control" />
                                </td>
                            </tr>
                            <tr>
                                <td>Net Price</td>
                                <td>
                                    <input v-model="hotel.net_price" type="text" class="au-input form-control" />
                                </td>
                            </tr>
                            <tr>
                                <td>Commission</td>
                                <td>
                                    <input v-model="hotel.commission" type="text" class="au-input form-control" />
                                </td>
                            </tr>
                            <tr>
                                <td>Profit</td>
                                <td>
                                    <input v-model="hotel.profit" type="text" class="au-input form-control" />
                                </td>
                            </tr>
                            <tr>
                                <td>Processed by</td>
                                <td>
                                    <input v-model="hotel.processed_by" type="text" class="au-input form-control" />
                                </td>
                            </tr>
                        </td>
                        <td class="col-md-2">
                        <th>
                            <input v-model="hotel.will_use" type="checkbox" class="mt-1 m-2 checkbox" />
                        </th>
                        </td>
                        </tr>
                    </tbody>
                </table>
            </div>
        </div>
    </div>
</template>
<script>
export default {
    data() {
        return {
            hotel: this.value
        }
    },
    props: {
        type: String,
        value: {
            type: Object
        }
    },
    watch: {
        value: {
            handler: function (newValue) {
                this.hotel = newValue;
            },
            deep: true
        },
        hotel: {
            handler: function (newValue) {
                this.$emit("input", newValue);
            },
            deep: true
        }
    },
    methods: {
        addGuest() {
            this.hotel.hotel_guests.push({
                first_name: '',
                last_name: '',
                guest_type: 'Adult'
            });
        },
        removeGuest(id, index) {
            if (id) {
                let _vm = this;
                this.$dialog
                    .confirm("Are you sure you want to delete this record?")
                    .then(function () {
                        _vm.axios
                            .delete("/hotel-guests/" + id)
                            .then(function () {
                                _vm.getSales();
                            })
                            .catch(function (error) {
                            });
                    })
                    .catch(function (error) {
                    });
            } else {
                this.hotel.hotel_guests.splice(index, 1)
            }
        },
    },
    created() {
        if (this.hotel.hotel_guests.length === 0) {
            this.addGuest();
        }
    }
}
</script>